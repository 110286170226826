/*
Version 0.104

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	10-10-2017
	- [TARGET] Changed all interaction targets to .js classes.
	16-02-2017
	- [CLOSE] Added: change SEO title back to old one
	- [METHOD] Changed: p2 to p to open popup
2016
	18-12-2016
	- [METHOD] Added: Specific upload code on avatar popup
	07-11-2016
	- [METHOD] Open and close now have option to hide html/body scrollbars
	05-11-2016
	- [BUILD] Build in new class formation
	26-10-2016
	- [FUNCTION] openPopup is now working with POST data instead or GET from URL 
	- [BORN] This class is born. Hooray!

!Usage

	popup.close();
	popup.open();
	
!Wishlist

	none
 
*/

var popup = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function() {
		
		/* Navigation: Exit a popup */
		$(document).on('click','.js-popup-close,.js-popup-background',function(e) { // no touchstart?
			e.preventDefault();
			
			/**
			If historyURL is the same as document.URL
			**/
			if(historyURL == document.URL) {
				popup.close();
			} else {
				router.beforeRoute(historyURL,["popup",0,0]);
			}
			
		});
		
	};

	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	
	/**
	Open Popup
	**/
	this.open = function(direct,target,data) {
		
		var popupDirect = typeof direct !== 'undefined' ? direct : 0;
		var popupTarget = typeof target !== 'undefined' ? target : 0;
		var popupData = typeof data !== 'undefined' ? data : '';
		
		popupDirect = parseInt(popupDirect);
		
		/**
		Build URL
		**/
		var popupUrl = '';
		
		/**
		When URL is not given in, then render Browser URL to catch popup window
		**/
		if(popupDirect === 0) {
			popupUrl = globalUrl + '/a/p' + renderSubUrl();	
		} else {
			popupUrl = globalUrl + '/a/p/' + popupTarget;
		}
		
		/**
		Open popup
		**/
		if(isPopupOpen === 0) {
			
			$('.js-popup-background').fadeIn(300);
			
			/**
			Send data by POST call and open popup on success
			**/		
			$.ajax({
			  url: popupUrl,
			  type: 'POST',
			  data: { obj: popupData },
			  success: function(html) {
				
				/**
				Load the popup
				**/
				$('.js-popup-loader').hide().html(html).promise().done(function() {
					
					/**
					Set: isPopupOpen
					**/
					isPopupOpen = 1;
					
					/**
					Get: Popup type
					**/
					popupType = $('.js-popup').attr('data-go-popuptype');
					
					/**
					Trigger: popup interaction
					**/
					specific_interactions.popup('open',popupType);
					
					/**
					Trigger: setPosition
					**/
					popup.setPosition();
						
					/**
					Set: History latest popup
					**/
					historyLatestPopup = document.URL;
					
				});
				
			  },
			  error: function(e) {
				
				// error
				
			  }
			});
			
		}
		
	};
	
	/**
	Close Popup
	**/
	this.close = function() {
		
		/**
		Get: Popup type
		**/
		popupType = $('.js-popup').attr('data-go-popuptype');
		
		/**
		Close popup
		**/
		if(isPopupOpen === 1) {
			$('.js-popup-background').fadeOut(300);
			$('.js-popup-loader').fadeOut(200, function() {
				
				/**
				Empty the .popup-loader container
				**/
				$('.js-popup-loader').empty();
				
				/**
				Show scrollbar
				**/
				$('body').css({'overflow':'auto'});
				
				/**
				Set: isPopupOpen var to 0
				**/				
				isPopupOpen = 0;
			});
		}
		
		/**
		Trigger: popup interaction
		**/
		specific_interactions.popup('close',popupType);
		
	};
	
	/**
	Positioning the popups by settings
	**/
	this.setPosition = function() {
		
		/**
		Set modal offscreen
		**/
		$('.js-popup-loader').css({
			'left':-9999
		}).show().promise().done(function() {
		
			/**
			Is popup open?
			**/
			if(isPopupOpen === 1) {
			
				/**
				Get variables
				**/
				var popupCenter = $('.js-popup').attr('data-go-center');
				var popupScroll = $('.js-popup').attr('data-go-scroll');
				
				/**
				Get width and height
				**/
				var popupHeight = $('.js-popup').height();
				var popupWidth = $('.js-popup').width();
				
				/**
				Hide
				**/
				$('.js-popup-loader').hide();
				
				/**
				Set scrolling on heights
				**/
				if(popupHeight > screenHeight) {
					
					$('.js-popup-loader').css({
						'position':'absolute',
						'top': $(window).scrollTop() + 40,
						'margin-top':0,						
						'left':(screenWidth/2),
						'margin-left':-(popupWidth/2)
						
					});
					
					$('body').css({'overflow':'auto'});
					
				} else {
					
					$('.js-popup-loader').css({
						'position':'fixed',
						'top':(screenHeight/2),
						'margin-top':-(popupHeight/2),
						'left':(screenWidth/2),
						'margin-left':-(popupWidth/2)
					});
				
					$('body').css({'overflow':'hidden'});
					
				}
				
				/**
				Show the popup
				**/
				$('.js-popup-loader').show();
				
			}
			
		});
		
	};
		
}).apply(popup); 