/*
Version 0.101

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage

	specific_videoplayer.onready();
	
!Wishlist

	none
	
*/
  
var specific_videoplayer = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.onready = function() {
		
		$(document).on('click','.js-videoplayer-play',function() {
			var youtube_key = $(this).parent().data('youtube-key');
			$(this).parent().html('<iframe src="https://www.youtube.com/embed/' + youtube_key + '?showinfo=0&autoplay=1&rel=0" frameborder="0" allowfullscreen></iframe>');
		});
		
	};

		
}).apply(specific_videoplayer); 