/*
Version 0.105

Made by: Finally // Alexander Schakel // www.finally.nl // alexander@finally.nl
Copyright 2016.

!Changelog

2016
	02-12-2016
	- [METHOD] Added coloring, colors in settings.js and added notifier errors
	01-12-2016
	- [METHOD] notifier method added
	30-11-2016
	- [BUILD] First build

!Usage

	loader.change(option); 0 = off, 1 is on
	
!Wishlist

	none
 
*/

var loader = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	// none
	
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	
	// none

	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************

	this.change = function(option) {
		
		specific_interactions.loader(option);
	
	};
		
}).apply(loader); 