/*
Version 0.120

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	16-11-2017
	- [METHOD] Added: event tracking for Piwik is now baked in the .go-page trigger
	07-11-2017
	- [METHOD] Changed: The check for .go-page is removed. Now checking for internal link by function is_internal_or_external();
	- [METHOD] Changed: The .go-popup is obsolute, but the Method is checking for .go-popup and .go-scroll
	02-11-2017
	- [METHOD] Changed: The .go-page will go obsolete, because the script now checks for all links if they are internal or external.
	20-10-2017
	- [METHOD] Added: .go-scroll option added for linking to specific 'on page' items to start a scroll action.
	03-10-2017
	- [METHOD] Changed: init() is not removed and replaced to the loadPage in specific_interactions. Because the init() was too fast.
	02-09-2017
	- [METHOD] Changed: The popstate now don't set the history URL anymore.
	29-08-2017
	- [METHOD] Changed: some stuff from afterRoute to beforeRoute
	- [VAR] 0 and 1 is now changed to 'page' or 'popup' in: router.beforeRoute(href,'page',$(this));
	- [VAR] urlParts is now url[];
	28-03-2017
	- [METHOD] Changed: the loadUrl is now checked by !== '';
	21-03-2017
	-[METHOD] Changed: the $().load() to $().ajax() with promises.
2016
	14-12-2016
	- [METHOD] Changed: Navigation addClass is now fixed on the button with js-nav-item1
	11-12-2016
	- [METHOD] Still better: historyURL = document.URL. Changed it back again.
	09-12-2016
	- [METHOD] Changed: historyURL is now given href instead of document.URL.
	06-12-2016
	- [METHOD] load pageLoader2 now have some delay time
	05-11-2016
	- [BUILD] First build

!Usage

	router.onready();
	router.loadPage();
	
	router.beforeRoute(href,option);
	
!Wishlist

	None yet
 
*/

var router = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function() {

		/**
		Navigation: Catch all normal links (but not Mailto or links to documents);
		**/
		$(document).on('click','a:not([href^="mailto\\:"],[href$=".pdf"],[href$=".docx"],[href$=".xlsx"])',function(e) { // no touchstart?

			var is_internal_or_external = new RegExp(projectHost);
			var track = $(this).attr('data-go-track');
			var href = $(this).attr('href');
			
			/**
			Analytics: Event tracking
			**/
			if(typeof track !== 'undefined') {
				var trackArray = track.split(',');
				googleanalytics.track('event',trackArray[0],trackArray[1],trackArray[2]);
			}
			
			/**
			Piwik: Event tracking
			**/
			if(piwikID !== '') {
				piwik.track('link','page',href);
			}
			
			/**
			Check if the HREF is internal or external
			**/
			if(is_internal_or_external.test($(this).attr('href'))){
			
				/**
				If class is not go-popup or go-scroll then go on!
				**/
				if(!$(this).hasClass('go-popup') && !$(this).hasClass('go-scroll')) { 
			
					/**
					Prevent Default
					**/
					e.preventDefault();
					
					/**
					Route in GO!
					**/
					router.beforeRoute(href,["page",0,0],$(this));
						
				}	
				
			}
			
		});
		
		/**
		Navigation: Popup link
		**/
		$(document).on('click','.go-popup',function(e) { // no touchstart?
			e.preventDefault();
			var href = $(this).attr('href');
			var popupTarget = $(this).attr('data-go-popup-target');
			var popupDirect = $(this).attr('data-go-popup-direct');
			var track = $(this).attr('data-go-track');
			
			/**
			Route
			**/
			router.beforeRoute(href,["popup",popupDirect,popupTarget],$(this));
			
			/**
			Analytics: Event tracking
			**/
			if(typeof track !== 'undefined') {
				var trackArray = track.split(',');
				googleanalytics.track('event',trackArray[0],trackArray[1],trackArray[2]);
			}
			
			/**
			Piwik: Event tracking
			**/
			if(piwikID !== '') {
				piwik.track('link','page',href);
			}
			
		});
		
		/**
		Navigation: Scroll link
		**/
		$(document).on('click','.go-scroll',function(e) { // no touchstart?
			e.preventDefault();
			var scrollToClass = $(this).attr('data-go-scrollto');
			var scrollMarge = $(this).attr('data-go-scrollmarge');
			var scrollSpeed = $(this).attr('data-go-scrollspeed');
			
			_offsetTop = $('.' + scrollToClass).offset().top;
			
			/**
			If scrollMage is defined, then calculage it.
			**/
			if(typeof scrollMarge !== 'undefined') {
				_offsetTop = parseInt(_offsetTop) + parseInt(scrollMarge);
			}
			/**
			If scrollSpeed is defined, then use it.
			**/
			if(typeof scrollSpeed !== 'undefined') {
				scrollSpeed = scrollSpeed;
			} else {
				scrollSpeed = 1000;
			}
			
			/**
			Scroll now
			**/
			$('html, body').stop().animate({
				scrollTop: _offsetTop
			}, scrollSpeed);
			
		});
	
		/**
		Navigation: Window Event Listener on HTML5 popstate 
		**/
		window.addEventListener("popstate", function(e) {
			e.preventDefault();
			var href = document.URL;
			router.beforeRoute(href,["pop",0,0]);
		});
		
	};
	
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	
	// none

	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************

	this.beforeRoute = function(href,optionArray,thisObj) {
		
		/**
		If option is page or popup set the new URL. If option is popstate, don't push the new URL to history
		**/
		if(optionArray[0] !== 'pop'){
			window.history.pushState('', 'New URL: ' + href, href);
		}
			
		/**
		Set latest URL information
		**/
		historyURL = document.URL;
		historyDocumentTitle = document.title;
		
		/**
		Get array of URL Parts
		**/
		url = getUrl();
		
		/**
		Build new URL
		**/
		newSubUrl = renderSubUrl();
		
		/**
		Trigger: beforeRoute
		**/
		specific_interactions.beforeRoute(href,optionArray,thisObj);
		
		/**
		Close the mobilemenu always on route
		**/
		specific_mobilemenu.toggle(1);
		
	};
	
	this.afterRoute = function(href,option) {
		
		/**
		Always close popup
		**/
		popup.close();
		
		/**
		Route page, pop(state) or popup
		**/
		switch(option) {
			
			case 'page': 	// Refresh on normal link or close popup close
			case 'pop': 	// Refresh on popstate
			
				switch(url[1]) {
				  default:
				  
					/**
					If historyURL is the same as document.URL
					**/
					if(isPopupOpen === 1) {
						if(historyURL == document.URL) {
							popup.close();
						} else {
							router.beforeRoute(historyURL,["popup",0,0]);
						}
					} else {
						router.loadPage();
					}
					
				  break;
				  
				  case 'error':
					  popup.open();
				  break;
				 
				}
			break;
			
			case 'popup': // Don't refresh on popup
			break;
			
		}
	
	};
	
	/* PAGES **************************************************************** */
	/* PAGES **************************************************************** */
	/* PAGES **************************************************************** */
	/* PAGES **************************************************************** */
	
	this.loadPage = function() {
		
		isLoading = 1;
		
		loadUrl = '';
		
		/**
		Get Routable URL
		**/
		loadUrl = specific_interactions.getURL();
		
		/**
		If loadUrl is not empty
		**/
		if(loadUrl !== '') {
		
			/**
			Load the URL in Page Loader
			**/
			$.ajax(loadUrl)
			.done(function(data) {
				specific_interactions.loadPage(data);
			})
			.always(function() {
			});
			
		}
		
		/**
		Set: History latest page
		**/
		historyLatestPage = document.URL;

		return false;
		
	};
		
}).apply(router); 