/*
Version 0.103

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	24-07-2017
	- [CLASS] Added: _paq.push(['setCustomUrl',window.location.pathname]);
	- [CLASS] Changed: name to Piwik instead of pw.
	18-07-2017
	- [METHOD] Added 'page' variable to simple track the active page and document.title
2016
	20-10-2016
	- [BORN] This class has born.

!Usage

	piwik.track(action,key1,key2);
	
!Wishlist

	No wishes
 
*/
  
var piwik = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	
	// none
	
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************

	this.track = function(action,key1,key2,key3) {
		
		switch(action) {
			
			case 'link':
				 _paq.push(['trackEvent',key1,key2]);
			break;
			
			case 'click':
				_paq.push(['trackEvent',key1,key2]);
			break;
			
			case 'goal':
				_paq.push(['trackGoal',key1]);
			break;
			
			case 'scroll':
				_paq.push(['trackEvent',key1]);
			break;
			
			case 'page':
				_paq.push(['setDocumentTitle', document.title]);
				_paq.push(['setCustomUrl',window.location.pathname]);
				_paq.push(['trackPageView']);
			break;
			
		}	 
	};

}).apply(piwik);