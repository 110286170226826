/*
Version 0.103

Made by: Finally // Alexander Schakel // www.finally.nl // alexander@finally.nl
Copyright 2016.

!Changelog

2017 
	05-09-2017
	- [CORE] Added: Url has now 10 parts.
	29-08-2017
	- [VAR] urlParts is now url[];
2016
	27-11-2016
	- [BORN] This class is born
	
!Wishlist

	none
 
*/

/**
Get URL Parts
**/
function getUrl() {

	var getPageUrl = '';
	var getPageUrlParts = '';

	getPageUrl = window.location.pathname.substr(globalUrlLength,1000);
	getPageUrlParts = getPageUrl.split('/');
	
	getPageUrlPartsArray = [];
	
	if(getPageUrlParts[1]) { getPageUrlPartsArray[1] = getPageUrlParts[1]; } else { getPageUrlPartsArray[1] = ''; } 
	if(getPageUrlParts[2]) { getPageUrlPartsArray[2] = getPageUrlParts[2]; } else { getPageUrlPartsArray[2] = ''; } 
	if(getPageUrlParts[3]) { getPageUrlPartsArray[3] = getPageUrlParts[3]; } else { getPageUrlPartsArray[3] = ''; } 
	if(getPageUrlParts[4]) { getPageUrlPartsArray[4] = getPageUrlParts[4]; } else { getPageUrlPartsArray[4] = ''; } 
	if(getPageUrlParts[5]) { getPageUrlPartsArray[5] = getPageUrlParts[5]; } else { getPageUrlPartsArray[5] = ''; }
	if(getPageUrlParts[6]) { getPageUrlPartsArray[6] = getPageUrlParts[6]; } else { getPageUrlPartsArray[6] = ''; }
	if(getPageUrlParts[7]) { getPageUrlPartsArray[7] = getPageUrlParts[7]; } else { getPageUrlPartsArray[7] = ''; }
	if(getPageUrlParts[8]) { getPageUrlPartsArray[8] = getPageUrlParts[8]; } else { getPageUrlPartsArray[8] = ''; }
	if(getPageUrlParts[9]) { getPageUrlPartsArray[9] = getPageUrlParts[9]; } else { getPageUrlPartsArray[9] = ''; } 
	if(getPageUrlParts[10]) { getPageUrlPartsArray[10] = getPageUrlParts[10]; } else { getPageUrlPartsArray[10] = ''; }
	
	/**
	Return array of URL parts
	**/
	return getPageUrlPartsArray;
	
}

/**
Build new URL
**/
function renderSubUrl() {
	
	var newUrl = '';
	
	/**
	More than one language available?
	**/
	if(languagesCounter > 1) {
	}
	
	/**
	Build string newUrl with keys
	**/
	if(url[1]) { newUrl = newUrl + '/' + url[1]; }
	if(url[2]) { newUrl = newUrl + '/' + url[2]; }
	if(url[3]) { newUrl = newUrl + '/' + url[3]; }
	if(url[4]) { newUrl = newUrl + '/' + url[4]; }
	if(url[5]) { newUrl = newUrl + '/' + url[5]; }
	if(url[6]) { newUrl = newUrl + '/' + url[6]; }
	if(url[7]) { newUrl = newUrl + '/' + url[7]; }
	if(url[8]) { newUrl = newUrl + '/' + url[8]; }
	if(url[9]) { newUrl = newUrl + '/' + url[9]; }
	if(url[10]) { newUrl = newUrl + '/' + url[10]; }
	
	/**
	Return URL string
	**/
	return newUrl;
}