/*
Version 0.101

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	03-10-2017
	- [BORN] This class is born. Hooray!

!Usage

	specific_slideshow.onready(
		selector:$(selector),
		slideSpeed:3000,
		slideAnimationSpeed:400
		
	);
	
!Wishlist

	none
	
*/
  
var specific_slideshow = window.specific_slideshow || {};

(function() {
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	this.onready = function(obj) {
	
		_this = obj.selector;
		
		/**
		Static values
		**/
		_this.slideActive = 1;
		_this.slideTotal = ($(_this.selector + ' .slide').length);
		
		/**
		Define settings
		**/
		_this.slideActive = 0;
		_this.slideSpeed = 3500;
		_this.slideAnimationSpeed = 400;
		
		/**
		Overrule settings
		**/
		if(obj.slideSpeed) { 
			_.slideSpeed = obj.slideSpeed;
		} 
		if(obj.slideAnimationSpeed) { 
			_this.slideAnimationSpeed = obj.slideAnimationSpeed;
		} 
		
		/**
		Catch slides and initialize slideshow
		**/
		_this.each(function (index, value) {
			specific_slideshow.init(_this);
		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
		
	this.init = function(_this) {
		
		/**
		Set first slide to active
		**/
		_this.find('.slide').hide();
  		_this.find('.slide').first().show();
		
		/**
		Play
		**/
		specific_slideshow.play(_this);
	
	};
		
	this.play = function(_this) {
		
		setTimeout(function() {
			slideNew = _this.slideActive + 1;
			specific_slideshow.GoToSlide(_this,slideNew);
		},_this.slideSpeed);
		
	};
	
	this.GoToSlide = function(_this,slideNew) {
		
		/**
		Check if the slideNew is last or first one
		**/
		slideNew = parseInt(slideNew);
		slideNext = parseInt(slideNew);
		slideActive = parseInt(_this.slideActive);
		
		if(slideNew > (_this.slideTotal-1)) {
			slideNext = 0;
			slideNew = 0;
		} 
		if (slideNew < 0) {
			slideNext = (_this.slideTotal);
			slideNew = slideNext;
		}
		
		/**
		Animate the slides
		**/
		slide1 = _this.find('.slide').get(slideActive);
		slide2 = _this.find('.slide').get(slideNext);
		
		$(slide1).fadeOut(_this.slideAnimationSpeed);
		$(slide2).fadeIn(_this.slideAnimationSpeed);
		
		/**
		Update active slide number
		**/
		_this.slideActive = slideNext;
		
		/**
		Repeat
		**/
		setTimeout(function() {
			slideNew = (slideNew + 1);
			specific_slideshow.GoToSlide(_this,slideNew);
		},_this.slideSpeed);
		
	};
		
}).apply(specific_slideshow); 