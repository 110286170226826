/*
Version 0.120

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	03-11-2017
	- [METHOD] Added: new option for using 'htmleditor' and 'htmleditor_adv' element in forms. The init will check all the form element and load TinyMCE Editor.
	26-10-2017
	- [METHOD] Changed: Initializing the uploader functions is now changed to two separate functions: upload image and upload document.
	- [METHOD] Added: DateTimePicker validation option. It's always checking for 00-00-0000 00:00 input of the date and time for now.
	- [METHOD] Added: DateTimePicker option for using with the forms class.
	- [METHOD] Added: .form-input can nu also be number and be checked with is numeric function
	15-10-2017
	- [METHOD] Changed: .form-input is now changed to js-form-input for JS interaction.
	14-10-2017
	- [METHOD] Added: Datepicker now using language settings from the settings JS class. It's not dynamic yet.
	13-10-2017
	- [METHOD] Added: Submit form now checks for variable clearonsubmit and when this is 1 then on submit all values be reset (empty).
	12-10-2017
	- [CORE] Changed this all to class-JS protocol. Classname: form
	- [CORE] Changed: Submit by ENTER is now always trigger and checks the FORM if one is focussed.
	- [CORE] Changed: some classes now has .js- selector. More to come.
	- [CORE] Changed: formProcess is now changed to global variable isFormProcess.
	- [METHOD] Changed: Pressing enter when in Input field is now captured and triggers submit.
	10-10-2017
	- [METHOD] Added: isValidDate checks given date-notation from form-element to check if date is correct or not.
	08-10-2017
	- [METHOD] Changed: Behaviour of form handeling is now moved to specific_interactions.formHandeling();
	01-10-2017
	- [METHOD] Changed: Scrolling to the wrong input is now stopped, because it was not working the right way. field.focus still works.
	27-09-2017 
	- [METHOD] Validation: Password is now working like Normaltext
	30-08-2017 
	- [METHOD] Validation is now checking if Popup is open and then don't scroll to the input field
2016
	27-11-2016
	- [METHOD] Fix: validate form handling: all at once, now does't walk on every input field.
	- [METHOD] Fix: Edit on validateInput when normaltext is checked. Now it's from 1 character instead of 2.
	- [METHOD] Fix: set onselect validateInput to initDatepicker methods. Now it's working onselect.
	25-11-2016
	- [UPLOAD] Init changed: findInput find value is now on ID
	21-11-2016
	- [BIND] Changed 'keyup' to 'input' for 'oninput' call of the realtime validator function
	- [METHOD] validateForm upgraded with PHP error messages which action (PHP) will provide like 'Error:errormessagetext'
	10-10-2016
	- [BORN] This class is born
	
!Wishlist
	
	- Datepicker language must be dynamic loaded on setting like datepicker(nl);
	- When completed the submit of a form, then scroll to .thanks class in viewwindow.
	- min_len and max_len must be checked on 'normaltext' keyup. Is it rhe right count of chars? then is valid.
 
*/

var form = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none

	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function() {
		
		/** 
		Trigger Submit on ENTER button when cursor is in a input field
		**/		
		$(document).keypress(function(e) {
			
			if(e.key == 'Enter') {
				_thisForm = $(e.target).closest("form").attr('id');
				
				if(typeof _thisForm != 'undefined' && isFormProcess === 0) {
					$('#' + _thisForm).find('.js-form-submit').trigger('click');
				}
				return false;
			}
			
		});
		
		/**
		Call some function on keyup input
		**/
		$(document).on('input focus','input',function(e) {
			
			inputType = $(this).data('type');
			inputRequired = $(this).data('required');
			
			/** 
			Check all inputs on keyup, except checkbox and radiobutton
			**/
			if(inputType != 'checkbox' && inputType != 'radiobutton' && inputRequired === 1) {
				form.validateInput('onkeyup',$(this),inputType);
			}
			
		});
		
		/**
		Call some function on keypress textarea
		**/
		$(document).on('input focus','textarea',function(e) {
			
			inputType = $(this).data('type');
			inputRequired = $(this).data('required');
			
			if(inputRequired === 1) {
				form.validateInput('onkeyup',$(this),inputType);
			}
					
		});
		
		/** 
		Go validate the specific form on click
		**/
		$(document).on('click','.js-form-submit',function(e) {
			/**
			Only go on if isFormProcess = 0
			**/
			if(isFormProcess === 0) {
				formParent = $(this);
				form.validateForm(formParent);
			}	
		});
		
		/**
		If Radiobutton-specific is clicked, select the value
		**/
		$(document).on('click','.js-radiobutton-specific',function(e) {
			
			/**
			Catch the variables
			**/
			_correspondingValuename = $(this).data('name');
			_correspondingValue = $(this).data('value');
			
			/**
			Unselect all specific radiobuttons
			**/
			$('.js-radiobutton-specific-' + _correspondingValuename).removeClass('active');
			
			/**
			Clicked is active
			**/
			/**
			Unselect all specific radiobuttons
			**/
			$(this).addClass('active');
			
			/**
			Set the values
			**/
			$('.js-form-input-' + _correspondingValuename).val(_correspondingValue);
			
		});
		
		/**
		If Checkbox-specific is clicked, select the value
		**/
		$(document).on('click','.js-checkbox-specific',function(e) {
			
			/**
			Catch the variables
			**/
			_correspondingValuename = $(this).data('name');
			_correspondingValue = $(this).data('value');
			
			if($('.js-form-input-' + _correspondingValuename).val()) {
				
				/**
				Set the values
				**/
				$('.js-form-input-' + _correspondingValuename).val('');
				
				/**
				Remove active
				**/
				$(this).removeClass('active');
				
			} else {
				
				/**
				Set the values
				**/
				$('.js-form-input-' + _correspondingValuename).val(_correspondingValue);
				
				/**
				Add active
				**/
				$(this).addClass('active');
				
			}
			
		});
		
		/**
		Trigger: forms init
		**/
		form.init();
		
	};
	
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	
	/**
	Initialize forms
	**/
	this.init = function() {
		
		/**
		Initialize PLUPLOADer Image listeners on Document Ready
		**/
		$('[id^="upload_image"]').each(function() {
			findInput = $(this).find('.form-input').attr('name');
			form.initUploaderImage($(this).attr('id'),findInput);
		});
		
		/**
		Initialize datepickers in form and validate when checked date
		**/
		countDatepickers = $('.form-input-date').length+1;
		
		for (i = 1; i < countDatepickers; i++) {
			getType = $('.form-input-date').eq(i-1).data('type');
			form.initDatepicker(i,getType);
		}
		
		/**
		Initialize datetimepickers in form and validate when checked date and time
		**/
		countDateTimepickers = $('.form-input-datetime').length+1;
		
		for (i = 1; i < countDateTimepickers; i++) {
			getType = $('.form-input-datetime').eq(i-1).data('type');
			form.initDateTimepicker(i,getType);
		}
		
		/**
		Initialize htmleditors in form
		**/
		form.initHTMLEditor();
		
	};

	/**
	Validate form input *****************************************************************************
	Validate form input *****************************************************************************
	Validate form input *****************************************************************************
	Validate form input *****************************************************************************
	**/
	
	this.validateInput = function(submittype,field,inputType) {
		
		switch(inputType) {
			
			case 'token':
				if (field.val() && field.val().length == 32) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// textfield or hidden
			case 'hidden':
			case 'normaltext':
			case 'password':
				if (field.val() && field.val().length >= 1) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// number
			case 'number':
				if (field.val() && !isNaN(parseInt(field.val()))) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// textarea
			case 'textarea':
				if (field.val() && field.val().length > 10) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// htmleditor
			case 'htmleditor_adv':
				if (field.val() && field.val().length > 10) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// date
			case 'date':
			case 'birthday':
			
				/**
				Check given date notation to validate
				**/
				if(field.data('valid')) {
					_validation = field.data('valid'); 
				} else {
					_validation = '';
				}
				
				/**
				Validate
				**/
				if (field.val() && isValidDate(field.val(),_validation) === true) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// datetime
			case 'datetime':
			
				var _validation = '00-00-0000 00:00';
			
				var _dateCheck = false;
				var _timeCheck = false;
			
				var datetime = field.val();
				
				if(datetime) {
					
					/**
					Grab the notation
					**/		
					var date = datetime.substring(0,10);
					var time = datetime.substring(11,16);
					
					/**
					Check the date and time
					**/				
					if (isValidDate(date) === true) {
						_dateCheck = true;
					}
					if (isValidTime(time) === true) {
						_timeCheck = true;
					}
				
				}
				
				/**
				Return validation
				**/
				if(_dateCheck === true && _timeCheck === true) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else {
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			
			break;
			
			// emailaddress
			case 'email':
				if (field.val() && isValidMail(field.val()) == 1) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
					
			// telephonenumber
			case 'telephone':
				if (field.val() && isValidTelephone(field.val()) == 1) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else { 
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// checkbox
			case 'checkbox':
				if (isChecked(field) === 1) {
					return validateInputHandling(submittype,field,inputType,'ok');
				} else {
					return validateInputHandling(submittype,field,inputType,'error');
				}
			break;
			
			// radiobutton
			case 'radiobutton':
			break;
			
			// upload file
			case 'upload':		
				if (isNull(field.val()) === false) {
					return form.validateInputHandling(submittype,field,inputType,'ok');
				} else {
					return form.validateInputHandling(submittype,field,inputType,'error');
				}
			break;
				
		}
			
	};
	
	/**
	Input error or OK handling ********************************************************************************
	Input error or OK handling ********************************************************************************
	Input error or OK handling ********************************************************************************
	Input error or OK handling ********************************************************************************
	**/
	this.validateInputHandling = function(submittype,field,inputtype,status,errorhandling) {
		
		/**
		Handle OnKeyup or onSubmit
		**/
		if(submittype == 'onkeyup' || submittype == 'onsubmit') {
			
			if(submittype == 'onkeyup') {
				
				if(status == 'ok') {
					field.css({'background':formNormalColorBackground,'color':formNormalColorText});
					field.next('.indicator').addClass('ok');
				} else {
					field.next('.indicator').removeClass('ok');
				}
			
			} else if (submittype == 'onsubmit') {
				
				if(status == 'ok') {
					
					/* only when checkbox or upload */
					if(
						inputtype == 'checkbox' || 
						inputtype == 'upload'
					) {
						return false;
					}
					
					/* only when input or textarea */
					field.css({'background':formNormalColorBackground,'color':formNormalColorText});
					field.next('.indicator').addClass('ok');
					
				} else {
					
					/* only when checkbox or upload */
					if(
						inputtype == 'checkbox' || 
						inputtype == 'upload'
					) {
						alert(field.data('notice'));
						return false;
					}
					
					/* only when input or textarea */
					field.next('.indicator').removeClass('ok');
					field.css({'background-color':formErrorColorBackground,'color':formErrorColorText});
					
					if(form.formErrorHandling == 'allatonce') {
						
						//$('html,body').animate({ scrollTop: $(formAction).offset().top - 100 }, 200,function() {
						//});
						
					} else {
						
						if(isPopupOpen === 0) {
					
							/* scroll to field and focus */	
							//$('html,body').animate({ scrollTop: $(field).offset().top - 100 }, 200,function() {
							//	field.focus();
							//});
							
							field.focus();
						
						}
					
					}
					
					return false;
				}
				
			}
		
		/**
		Handle OnCount
		**/	
		} else if (submittype == 'count') {
			
			if(status == 'ok') {
				return true;
			} else {
				return false;
			}
		
		}
		
	};
	
	/**
	Validate form on submit **********************************************************************************
	Validate form on submit **********************************************************************************
	Validate form on submit **********************************************************************************
	Validate form on submit **********************************************************************************
	**/
	
	this.validateForm = function(i) {
	
		/**
		Get form, form-action and formname by ID
		**/
		thisForm = i.parent().closest('.form');
		thisFormAction = i.parent().closest('.form-action');
		thisFormClearOnSubmit = thisForm.data('clearonsubmit');
		thisFormErrorHandling = thisForm.data('errorhandling');
		thisFormError = thisForm.data('errormessage');
		thisFormName = thisForm.attr('id');
		
		/**
		Get and count all fields in form
		**/
		countInputTotal = thisForm.find('.form-input').length;
		countInputRequired = 0;
		countInputTotalRequired = 0;
		
		/**
		Count and check all required fields
		**/
		for (i = 0; i < countInputTotal; i++) {
			if (thisForm.find('.form-input:eq('+i+')').data('required') == 1) {
			countInputTotalRequired++;
			}
		}
		
		/**
		When formErrorHandling is none. Just go without validation.
		**/
		if(thisFormErrorHandling != 'none') {
			
			/**
			Loop through fields and validate
			**/
			for (i = 0; i < countInputTotal; i++) {
				
				stopLoop = 0;
				
				inputType = thisForm.find('.form-input:eq('+i+')').data('type');
				
				// is required field?
				if(thisForm.find('.form-input:eq('+i+')').data('required') == 1) {
					
					// validate the input onsubmit
					form.validateInput('onsubmit',thisForm.find('.form-input:eq('+i+')'),inputType);
						
					// validate the input on count
					if(form.validateInput('count',thisForm.find('.form-input:eq('+i+')'),inputType) === true) {
						
						// input is validated OK
						countInputRequired++;
						
					} else {
						
						// not validated and check if error handling is onebyone
						if(thisFormErrorHandling == 'onebyone') {
							stopLoop = 1;
						}
						
					}
					
				}
				
				if(stopLoop == 1) {
					break;
				}
				
			}
			
		} else {
			
			countInputTotalRequired = countInputRequired;
			
		}
		
		/**
		If all required fields are validated OK then process form
		**/
		if(countInputRequired == countInputTotalRequired) {
			
			/**
			Form is validated, set processForm to 1
			**/
			isFormProcess = 1;
			
			/**
			Process interaction: change button text
			**/
			thisForm.find('.form-submit').addClass('active').html(thisForm.find('.form-submit').data('textonsubmit'));
	
			/** 
			Set all inputs to read-only
			**/
			thisForm.find('.form-input').prop('readonly',true);
			thisForm.find('.form-input').css({'background-color':formReadOnlyColorBackground,'color':formReadOnlyColorText});
				
			/**
			TinyMCE Triggersave
			**/			
			tinyMCE.triggerSave();
						
			/** 
			Get all values from the form
			**/
			allValues = $(thisForm).serialize();
			
			/** 
			Post to action file
			**/
			$.post(globalUrl + "/a/f/" + thisFormName, allValues, function(data) {
				
				/**
				Clear all values on submit
				**/
				if(thisFormClearOnSubmit === 1) {
					 $(thisForm).find('input[type=text],input[type=password],textarea').val('');
					 $(thisForm).find('select').find('option').prop("selected", false);
					 //$(thisForm).find('input[type=radio]').prop("checked", false);
				}
					
				specific_interactions.formHandeling(thisFormAction,thisFormName,data);
					
			});
			
		} else {
			
			
		}
	
	};
	
	/**
	Cancel form ********************************************************************************
	Cancel form ********************************************************************************
	Cancel form ********************************************************************************
	Cancel form ********************************************************************************
	**/
	
	this.cancelForm = function(thisForm) {
		
		/**
		Form is not validated, set processForm to 0
		**/
		isFormProcess = 0;
		
		/** 
		Release all inputs that are readonly
		**/
		thisForm.find('.form-input').prop('readonly',false);
		thisForm.find('.form-input').css({'background-color':formNormalColorBackground,'color':formNormalColorText});
		
		/**
		Process interaction: change button text back
		**/
		thisForm.find('.js-form-submit').removeClass('active').html(thisForm.find('.form-submit').data('texttosubmit'));
			
	};
	
	/**
	Date and DateTime picker function ********************************************************************************
	**/
	
	this.initDatepicker = function(i,type) {
	
		if(type == 'birthday') {
	
			datePickerOptions = {
				dateFormat: 'dd-mm-yy',
				yearRange: '1950:2017',
				changeMonth: true,
				changeYear: true,
				defaultDate: new Date(1970,0,01),
				monthNamesShort: $.datepicker.regional.nl.monthNames,
				onSelect: function() {
					form.validateInput('onkeyup',$(this),'date');
				}
			};
	
		} else {
			
			datePickerOptions = {
				dateFormat: 'dd-mm-yy',
				defaultDate: showDateToday(),
				monthNamesShort: $.datepicker.regional.nl.monthNames,
				onSelect: function() {
					form.validateInput('onkeyup',$(this),'date');
				}
			};
			
		}
	
		$.datepicker.setDefaults($.datepicker.regional.nl);
		
		$('.form-input-date').eq(i-1).datepicker(datePickerOptions);
			
	};
	
	this.initDateTimepicker = function(i,type) {
		
		/**
		Set language
		**/
		$.datetimepicker.setLocale('nl');
		
		/**
		DateTimePicker options
		**/
		DateTimePickerOptions = {
			format:'d-m-Y H:i',
			defaultDate:new Date(),
			defaultTime:'12:00',
			step:60,
			onSelectDate: function(current_date,input) {
				form.validateInput('onkeyup',input,'datetime');
			},
			onSelectTime: function(current_time,input) {
				form.validateInput('onkeyup',input,'datetime');
			}
		};
			
		/**
		Init datetimepicker
		**/
		$('.form-input-datetime').eq(i-1).datetimepicker(DateTimePickerOptions);
	};
	
	/**
	Upload functions *************************************************************************************
	Upload functions *************************************************************************************
	Upload functions *************************************************************************************
	Upload functions *************************************************************************************
	**/
	this.initUploaderDocument = function(id,field) {
		
		var uploader = new plupload.Uploader({
			
			runtimes : 'html5,flash,html4',
			browse_button : id + '_upload_button',
			container: document.getElementById(id),
			url : globalUrl + '/statics/upload-document', // globalUrl + '/statics/upload' MOET ZO..
			multiple_queues : false,
			multi_selection : false,
			max_file_count : 1,
			autostart: true,
			
			filters : {
				max_file_size : '20mb',
				mime_types: [
					{title : "Documenten", extensions : "pdf,doc,docx"},
				]
			},
		
			init: {
				
				PostInit: function() {
				},
		
				FilesAdded: function(up, files) {
					up.start();
				},
		
				UploadProgress: function(up, file) {
					isUploadProcess = 1;
					$('#' + id + ' .upload-process').fadeIn(200);
					$('#' + id + ' .upload-process').html('<span>' + file.percent + '</span>%');
				},
				
				FileUploaded: function(up,file,r) {	
					isUploadProcess = 0;				
					$('#' + id + ' .upload-info span').html('Bestand toegevoegd');
					$('#' + id + ' .upload-process').fadeOut(200);
					$('.form-input-' + field).val(r.response.trim());
				},
		
				Error: function(up, err) {
					isUploadProcess = 0;
					//console.log("\nError #" + err.code + ": " + err.message);
					if(err.code == '-600') { 
						alert('Dit bestand is te groot. Probeer een andere afbeelding.');
					}
					if(err.code == '-601') { 
						alert('Dit bestand heeft een onjuist bestandsformaat. Probeer een JPG of een MP4.');
					} 
					
				}
				
			}
		});
	
		uploader.init();

	};
	
	this.initUploaderImage = function(id,field) {
		
		var uploader = new plupload.Uploader({
			
			runtimes : 'html5,flash,html4',
			browse_button : id + '_upload_button',
			container: document.getElementById(id),
			url : globalUrl + '/statics/upload-image', // globalUrl + '/statics/upload' MOET ZO..
			multiple_queues : false,
			multi_selection : false,
			max_file_count : 1,
			autostart: true,
			
			filters : {
				max_file_size : '10mb',
				mime_types: [
					{title : "Afbeeldingen", extensions : "jpg,png"},
				]
			},
		
			init: {
				
				PostInit: function() {
				},
		
				FilesAdded: function(up, files) {
					up.start();
				},
		
				UploadProgress: function(up, file) {
					isUploadProcess = 1;
					$('#' + id + ' .upload-process').fadeIn(200);
					$('#' + id + ' .upload-process').html('<span>' + file.percent + '</span>%');
				},
				
				FileUploaded: function(up,file,r) {	
					isUploadProcess = 0;				
					$('#' + id + ' .upload-info span').html('Bestand toegevoegd');
					$('#' + id + ' .upload-process').fadeOut(200);
					$('.form-input-' + field).val(r.response.trim());
				},
		
				Error: function(up, err) {
					isUploadProcess = 0;
					//console.log("\nError #" + err.code + ": " + err.message);
					if(err.code == '-600') { 
						alert('Dit bestand is te groot. Probeer een andere afbeelding.');
					}
					if(err.code == '-601') { 
						alert('Dit bestand heeft een onjuist bestandsformaat. Probeer een JPG of een MP4.');
					} 
					
				}
				
			}
		});
	
		uploader.init();

	};
	
	/**
	TinyMCE Editor function ********************************************************************************
	**/
	
	this.initHTMLEditor = function() {
	
		/**
		No HTML options
		**/
		tinymce.init({
			selector: '.form-input-htmleditor',
			skin: 'lightgray',
			plugins: [
				'paste'
			],
			menubar: false,				// no menubar
			elementpath: false,			// no elementpath
			toolbar: false,				// no toolbar
			branding: false,			// no branding
			paste_as_text: true,		// strip styling on paste
			forced_root_block : false, 	// don't make p blocks
			element_format : 'html', 	// makes <br /> to <br>
			init_instance_callback: function (editor) {
				editor.on('Change', function (e) {
				  	// validate here?
				});
			}
			
		});
	
		/**
		With simple HTML styling
		**/
		tinymce.init({
			selector: '.form-input-htmleditor_adv',
			skin: 'lightgray',
			plugins: [
				'paste,lists,link'
			],
			toolbar: 'bullist | bold italic | link',
			menubar: false,				// no menubar
			elementpath: false,			// no elementpath
			branding: false,			// no branding
			paste_as_text: true,		// strip styling on paste
			forced_root_block : false, 	// don't make p blocks
			element_format : 'html', 	// makes <br /> to <br>
			init_instance_callback: function (editor) {
				editor.on('Change', function (e) {
				  	// validate here?
				});
			}
			
		});
			
			
	};

}).apply(form); 