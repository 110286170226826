/*
Version 0.100

Made by: Finally // Alexander Schakel // www.finally.nl // alexander@finally.nl
Copyright 2016.

!Changelog

2017
	23-03-2017
	- [BUILD] First build

!Usage

	loader.change(option); 0 = off, 1 is on
	
!Wishlist

	none
 
*/

var notifier = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	// none
	
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	// ONREADY *****************************************************************
	
	// none

	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************

	this.change = function (i,error,message) {
		
		specific_interactions.notifier(i,error,message);
		
	};
		
}).apply(notifier); 