/*
Version 0.105

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	13-10-2017
	- [METHOD] Changed: the check of the object is div, span or img is now in the loading process.
	05-10-2017
	- [METHOD] Bugfix: Preload an <img> is repaired now.
	27-08-2017
	- [METHOD] Change: Preload is now async
	23-08-2017
	- [METHOD] Added: If object is an hyperlink (a) it was not there. Now it is. Also combined DIV,SPAN and A.
	- [BORN] This class is born. Hooray!

!Usage

	interactions.onready();
	
!Wishlist

	none
	
*/
  
var images = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.preload = function() {
		
		/**
		Catch all go-preload-img classes
		**/
		$('.go-preload-img').each(function() {
			
			/**
			Declare local variables
			**/
			var fadeIn = 1;
			var thisObj = $(this);
			var preloadImage = thisObj.attr('data-go-img');
			
			/**
			Has?
			**/
			if(thisObj.hasClass('no-fadein')) {
				fadeIn = 0;
			}
			
			/**
			Load image
			**/
			var img = $("<img />").attr('src', preloadImage)
				.on('load', function() {
					
				  /**
				  Check object is DIV, SPAN or IMG
				  **/
				  if(thisObj.is('div')) {
					  thisObjIs = 'div';
				  }
				  if(thisObj.is('span')) {
					  thisObjIs = 'span';
				  }
				  if(thisObj.is('a')) {
					  thisObjIs = 'a';
				  }
				  if(thisObj.is('img')) {
					  thisObjIs = 'img';
				  }
					
				  if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth === 0) {
					  
					  alert('broken image!');
					  
				  } else {
					  
					  /**
					  DIV, SPAN or A
					  **/
					  if(
						  thisObjIs == 'div' ||
						  thisObjIs == 'span' ||
						  thisObjIs == 'a'
					  ) {
						  
						  setTimeout(function(){
							 
							 thisObj.animate({'opacity':0},100,function() {
								  
								  /**
								  Set the new image URL
								  **/
								  thisObj.css('background-image','url(' + preloadImage + ')');
								  
								  /**
								  Remove the go-preload-img class
								  **/
								  thisObj.removeClass('go-preload-img');
								  
								  /**
								  Animate the new image if fadeIn = default: 1
								  **/
								  if(fadeIn === 1) {
									  thisObj.animate({'opacity':1},200,function() {
										
										/**
										Remove the go-preload-img class
										**/
										thisObj.removeAttr('data-go-img');  
										  
									  });
								  }
								 
								  
							  });
							  
						  },Math.random() * 200);
							  
						  
					  }
					  
					  /**
					  IMG
					  **/
					  
					  if(thisObjIs == 'img') {
						  
						  /**
						  Append the image
						  **/
						  thisObj.attr('src',preloadImage);
						  
						  /**
						  Remove the go-preload-img class
						  **/
						  thisObj.removeClass('go-preload-img');
						  
						  /**
						  Remove the go-preload-img class
						  **/
						  thisObj.removeAttr('data-go-img');
						  
					  }
					  
					  
				  }
			  });
			
		});
  		
	};
		
}).apply(images); 