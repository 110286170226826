/*
Version 0.110

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	08-10-2017
	- -[METHOD] Added: formHandeling() method for specific interaction on specific forms.
	29-08-2017
	- [VAR] urlParts is now url[];
	- [METHOD] New Method: Router: loadPage on behalf of init from Router.
	02-07-2017
	- [METHOD] Added: specific_interactions.windowOrientationChange() to bind interaction to landscape or portrait mode changes.
	11-04-2017
	- [METHOD] Changed: specific_interactions.getURL is not in charge of 'not found' anymore.
	21-03-2017
	- [METHOD] Added: new methods for specific getURL t.b.v. Router Class.
2016
	06-12-2016
	- [DATEPICKER] Added: startDatepicker onclick when date selection is needed and then check type
	04-12-2016
	- [ONREADY] Added: the username bindings
	- [ONREADY] Added: the bodyfat calculate checkbox bindings
	- [DATEPICKER] Added the datepicker interaction
	- [BORN] This class is born. Hooray!

!Usage

	interactions.onready();
	
!Wishlist

	none
	
*/
  
var specific_interactions = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.beforeRoute = function(href,optionArray,thisObj) {
		
		var popupOrPage = optionArray[0];
		
		/**
		Check if next URL is an popup or page
		**/
		if(popupOrPage === 'popup') {
			
			var popupDirect	= optionArray[1];
			var popupTarget	= optionArray[2];
			var popupData 	= optionArray[3];
			
			/**
			Popup: Open
			**/
			popup.open(popupDirect,popupTarget,popupData);
			
			/**
			Go on and load the next page
			**/
			router.afterRoute(href,'popup');
			
		} else {
			
			/**
			Do some animation with the Page Loader
			**/
			specific_interactions.loader(1,1);
			
			/**
			Go on and load the next page
			**/	
			router.afterRoute(href,'page');
			
		}
  		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		// INIT *****************************************************************************
		// INIT *****************************************************************************
		// INIT *****************************************************************************
		// INIT *****************************************************************************
			
			/**
			FIRSTLOAD
			**/	
			this.firstload = function() {
				
				/**
				Trigger: images preloader
				**/
				images.preload();
				modal.onready();
				popup.onready();
				form.onready();
				
				/**
				Trigger: onready classes
				**/
				specific_mobilemenu.onready();
				specific_videoplayer.onready();
				
			};
			
			/**
			INIT ******************************************************************************
			**/
			this.init = function(data) {
				
				/**
				Main navigation: Get and set active button 
				**/
				$('.js-nav-item').removeClass('active');
				$('.js-nav-item[data-go-url="' + url[1] + '"]').addClass('active');
				
				/**
				Initialize slideshow
				**/
				// specific_slideshow.onready({
				// 	selector:$('.slideshow')
				// });
				
				/**
				Check URL for opening some popups
				**/
				// if(
				// 	url[4] == 'inschrijven' && 
				// 	url[2] !== ''
				// ) {
				// 	if(isPopupOpen === 0) {
				// 		popup.open(1,'inschrijven/' + url[2],0);
				// 	}
				// }
				
				if(
					url[3] == 'video' && 
					url[4] !== ''
				) {
					if(isPopupOpen === 0) {
						popup.open(1,'video/' + url[4],0);
					}
				}
				
				/**
				3d Party: Tooltipster activate
				**/
				$('.tooltip').tooltipster({
					maxWidth:200,
					delay:0
				});
				
				/**
				Trigger: Colorbox
				**/
				$('a.js-thumbnail').colorbox({
					maxWidth:'95%', 
					maxHeight:'95%'
				});
				$('a.js-gallery').colorbox({
					maxWidth:'95%', 
					maxHeight:'95%',
					rel:'.js-gallery'
				});
									
			};
		
		// POPUPS ******************************************************************************
		// POPUPS ******************************************************************************
		// POPUPS ******************************************************************************
		// POPUPS ******************************************************************************
		
		this.popup = function(openorclose,popuptype) {
			
			/**
			Open popup
			**/
			if(openorclose == 'open') {
				
				/**
				Trigger: images preloader
				**/
				images.preload();
				
			}
			
			/**
			Close popup
			**/
			if(openorclose == 'close') {
				
				/**
				Get: Popup type
				**/
				popupType = $('.popup').data('go-popuptype');
				
				/**
				Trigger keypress off
				**/
				specific_interactions.keypress('off',popuptype);
				
			}
			
				
		};
		
		// KEYPRESS ******************************************************************************
		// KEYPRESS ******************************************************************************
		// KEYPRESS ******************************************************************************
		// KEYPRESS ******************************************************************************
		
		this.keypress = function(action,type) {
			
			if(action == 'on') {
				
			}
			
			if(action == 'off') {
				
			}
			
		};
		
		this.catchKeypress = function(e,type) {
			
		};
	
		// ROUTER *****************************************************************************
		// ROUTER *****************************************************************************
		// ROUTER *****************************************************************************
		// ROUTER *****************************************************************************
		
			/**
			Router: get specific URL
			**/
			this.getURL = function() {
				
				/**
				Get URL parts
				**/
				url = getUrl();
				
				/**
				Route
				**/
				_returnUrl = '';
				_returnUrl = globalUrl + renderSubUrl();
				
				if (typeof _returnUrl !== 'undefined') {
					return _returnUrl;
				} else {
					return false;
				}
				
			};
		
			/**
			Router: loadPage
			**/
			this.loadPage = function(data) {
					
				/**
				Fade current page out and the new page in when loading is done
				**/
				$('.page-loader').stop().animate({'opacity':0},100,function() {
					
					setTimeout(function() {
						
						/**
						Set HTML
						**/
						$('.page-loader').html(data);
						
						/**
						Fade in Page Loader
						**/
						$('.page-loader').stop().animate({'opacity':1},200);
						
						/**
						Trigger: Resize Window
						**/
						specific_interactions.windowResize();
						
						/**
						Trigger: images preloader
						**/
						images.preload();
						
						/**
						Trigger: forms init
						**/
						form.init();
						
						/**
						Default: init();
						**/
						init();
						
					},200);
					
				});
				
			};
		
		// WINDOW ACTIONS *****************************************************************
		// WINDOW ACTIONS *****************************************************************
		// WINDOW ACTIONS *****************************************************************
		// WINDOW ACTIONS *****************************************************************
			
			/**
			Window: resize
			**/
			this.windowResize = function(scrolled,h,w) {
				
				/**
				Spotlight: Set height
				**/
				if(w < 1000) {
					$('.js-spotlight').css('height', 'auto');
				} else {
					$('.js-spotlight').height(h);
				}
								
			};
			
			/**
			Window: scroll
			**/
			this.windowScroll = function(scrolled,h,w) {
								
			};
			
			/**
			Window: orientation
			**/
			this.windowOrientationChange = function() {
				
			};
		
		// FORMHANDELING *****************************************************************
		// FORMHANDELING *****************************************************************
		// FORMHANDELING *****************************************************************
		// FORMHANDELING *****************************************************************
		
			this.formHandeling = function(formaction,formname,data) {
				
				/**
				Error
				**/
				if(data.substring(0,5) === 'Error') {
					
					/**
					Catch unusual PHP message
					**/
					errorMessage = data.split('§');
					
					/**
					Show error message
					**/
					specific_interactions.error(formname,errorMessage[1]);
					
					/**
					Cancel and reset the form 
					**/
					form.cancelForm(formaction);
					
				/**
				Success
				**/
				} else {
					
					switch(formname) {
					
						default:
							
							// data
							// $('html,body').scrollTop(0); don't scroll to top!
					
							$(formaction).html(data);
							
							processForm = 0;
							
						break;
					
					}
				
				}
				
			};
			
			this.error = function(i,errormessage) { 
			
				switch(i) {
					
					default:
						modal.open('error',i,errormessage);
					break;
					
				}
			
			};
		
		// LOADER *****************************************************************
		// LOADER *****************************************************************
		// LOADER *****************************************************************
		// LOADER *****************************************************************
		
			this.loader = function(i,option) {
				
				switch (i) {
					
					/**
					Main Loader
					**/
					default:
				
						switch(option) {
							
							default:
							case 0:
							
								/**
								Hide loader
								**/
								$('.js-loader').stop().css('width',0);
								
							break;
							
							case 1:
							
								/**
								Show loader, but delay on 200ms
								**/
								$('.js-loader').stop().delay(200).animate({'width':$(window).width()},2000);
								
							break;
							
						}
					
				}
				
			};

		// NOTIFIER *****************************************************************
		// NOTIFIER *****************************************************************
		// NOTIFIER *****************************************************************
		// NOTIFIER *****************************************************************
				
			this.notifier = function(i,error,message) {
				
				/**
				Set coloring
				**/
				if(error == 1) {
					_backgroundColor = notifierErrorBackgroundColor;
					_textColor = notifierErrorTextColor;
				} else {
					_backgroundColor = notifierNormalBackgroundColor;
					_textColor = notifierNormalTextColor;
				}
				
				/**
				Show notify
				**/
				switch (i) {
				
					default:
					
						/**
						Set notifier colors
						**/
						$('.notifier-message').html(message).css('background-color',_backgroundColor).css('color',_textColor);
						
						/**
						Show notifier
						**/
						$('.notifier').show(0,function() {
							
							setTimeout(function() {
								$('.notifier').stop().fadeOut(400);
							},2500);
							
						});
						
					break;
					
				}
				
			};
			
		// MODALS ******************************************************************************
		// MODALS ******************************************************************************
		// MODALS ******************************************************************************
		// MODALS ******************************************************************************
		
		this.modal = function(openorclose) {
			
			/**
			Open modal
			**/
			if(openorclose == 'open') {
	
			}
			
			/**
			Close modal
			**/
			if(openorclose == 'close') {
			
			}
			
				
		};
		
}).apply(specific_interactions); 