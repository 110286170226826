/*
Version 0.102

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016.

!Changelog

2017
	21-09-2018
	- [METHOD] Added: Event tracking with UAC
	18-10-2017
	- [BORN] This class has born.

!Usage

	googleanalytics.track(action,key1,key2);
	
!Wishlist

	- Make it possible to use the Tag Manager (Data Layer) option.
 
*/
  
var googleanalytics = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	
	// none
	
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************

	this.track = function(action,key1,key2,key3) {
		
		switch(action) {
			
			case 'pageview':
				
				/**
				Google Tag Manager variant
				**/
			
				//dataLayer.push({
				//	'event' : 'VirtualPageview',
				//	'virtualPageURL' : location.pathname,
				//	'virtualPageTitle' : document.title
				//});
				
				/**
				Universal Google Analytics
				**/

				ga('send','pageview',location.pathname);
				
			break;
			
			case 'event':
			
				/**
				Universal Google Analytics
				**/
			
				ga('send','event',key1,key2,key3);
				
			break;
			
		}	 
	};

}).apply(googleanalytics);