/*
Version 0.104

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	15-10-2017
	- [METHOD] Add and remove .active class to the js-menu-button.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage

	interactions.onready();
	
!Wishlist

	none
	
*/
  
var specific_mobilemenu = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.onready = function() {
		
		/**
		Bind listeners
		**/
		$(document).on('click','.js-menu-button',function() {
			specific_mobilemenu.toggle();
		});
				
	};
	
	this.toggle = function(closeDirectly) {
		
		closeDirectly = typeof closeDirectly !== 'undefined' ? closeDirectly : 0;
		
		if(closeDirectly === 1) {
			
			if(isMobileMenuOpen === 1) {
				specific_mobilemenu.close();
			}
			
		} else {
			
			if(isMobileMenuOpen === 0) {
				specific_mobilemenu.open();
			} else {
				specific_mobilemenu.close();
			}
		
		}
		
  		
	};
	
	this.open = function() {
		
		var scrolledNow = $(window).scrollTop();
		
		mobileMenuSetScrolled = scrolledNow;
		
		$('.js-mobile-menu').animate({'top':0},200);
		
		/**
		Add .active to the menu-button
		**/
		$('.js-menu-button').addClass('active');
		
		/**
		Set the variable
		**/
		isMobileMenuOpen = 1;	
	};
	
	this.close = function() {
		
		/**
		Hide the menu on top
		**/
		$('.js-mobile-menu').animate({'top':-($('.js-mobile-menu').height() + 1000)},200);
		
		/**
		Scroll the menu back to top
		**/
		$('.js-mobile-menu .scroller').animate({ scrollTop: 0 }, "fast");
		
		/**
		Remove .active to the menu-button
		**/
		$('.js-menu-button').removeClass('active');
		
		/**
		Set the variable
		**/
		isMobileMenuOpen = 0;
		
	};
		
}).apply(specific_mobilemenu); 