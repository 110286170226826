/*
Version 0.100

Made by: Finally // Alexander Schakel // www.finally.nl // alexander@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	15-10-2017
	- [METHOD] setPosition for centering looks now for modalHeight to .js-modal-loader for height.
	10-10-2017
	- [BORN] This class is born. Hooray!

!Usage

	modal.open();
	modal.close();
	
!Wishlist

	none
 
*/

var modal = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function() {
		
		/* Navigation: Exit a modal */
		$(document).on('click','.js-modal-close',function(e) { // no touchstart?
			e.preventDefault();
			
			modal.close();
			
		});
		
	};

	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	
	/**
	Open Modal
	**/
	this.open = function(url,ref,message) {
		
		/**
		Set variables
		**/
		if(!ref) {
			ref = '';
		}
		
		if(!message) {
			message = '';
		}
		
		/**
		Build URL
		**/
		var modalUrl = globalUrl + '/a/p/' + url;	
		
		/**
		Open modal
		**/
		if(isModalOpen === 0) {
			
			$('.modal-background').fadeIn(300);
			
			/**
			Send data by POST call and open popup on success
			**/		
			$.ajax({
			  url: modalUrl,
			  type: 'POST',
			  data: {
				  i:ref,
				  e:message
			  },
			  success: function(html) {
				  
				/**
				Load the popup
				**/
				$('.js-modal-loader').html(html).promise().done(function() {
					
					/**
					Set: isModalOpen
					**/
					isModalOpen = 1;
					
					/**
					Trigger: setPosition
					**/
					modal.setPosition();
					
				});
				
			  },
			  error: function(e) {
				
				// error
				
			  }
			});
			
		}
		
	};
	
	/**
	Close Modal
	**/
	this.close = function() {
		
		/**
		Close modal
		**/
		if(isModalOpen === 1) {
			
			$('.js-modal-background').fadeOut(300);
			$('.js-modal-loader').fadeOut(200, function() {
				
				/**
				Empty the .popup-loader container
				**/
				$('.js-modal-loader').empty();
				
				/**
				Show scrollbar
				**/
				$('body').css({'overflow':'auto'});
				
			});
			
			/**
			Set: isPopupOpen var to 0
			**/				
			isModalOpen = 0;
			
		}
		
		/**
		Trigger: modal interaction
		**/
		specific_interactions.modal('close');
		
	};
	
	/**
	Positioning the popups by settings
	**/
	this.setPosition = function() {
		
		/**
		Set modal offscreen
		**/
		$('.js-modal-loader').css({
			'left':-9999
		}).show().promise().done(function() {
		
			/**
			Is modal open?
			**/
			if(isModalOpen === 1) {
			
				/**
				Get width and height
				**/
				var modalHeight = $('.js-modal').height();
				var modalWidth = $('.js-modal').width();
				
				/**
				Hide
				**/
				$('.js-modal-loader').hide();
				
				/**
				Set scrolling on heights
				**/
				if(modalHeight > screenHeight) {
					
					/**
					Set CSS
					**/
					$('.js-modal-loader').css({
						'position':'absolute',
						'top': $(window).scrollTop() + 40,
						'margin-top':0
					});
					
					$('body').css({'overflow':'auto'});
					
				} else {
					
					/**
					Set CSS
					**/
					$('.js-modal-loader').css({
						'position':'fixed',
						'top':(screenHeight/2),
						'margin-top':-(modalHeight/2),
						'left':(screenWidth/2),
						'margin-left':-(modalWidth/2)
					});
					
					$('body').css({'overflow':'hidden'});
					
				}
				
				/**
				Show the modal
				**/
				$('.js-modal-loader').show();
				
			}
		
		});
		
	};
		
}).apply(modal); 