/*
Version 0.103

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	31-10-2017
	- [METHOD] Changed: The scroll to top now checks for debug setting and for _is_debug_scrolltotop setting
	17-10-2017
	- [METHOD] Added: New variable checks if development is on. Then don't scroll to top after init();
	29-08-2017
	- [VAR] urlParts is now url[];
2016
	30-11-2016
	- [METHOD] Added: loader.change to call the loader
	
!Wishlist

	none
 
*/

/* READY **************************************************************** */
/* READY **************************************************************** */
/* READY **************************************************************** */
/* READY **************************************************************** */

$(document).ready(function() {
	
	if(isFirstLoad === 1) {
		firstload();
	}

});

/* FIRST LOAD **************************************************************** */
/* FIRST LOAD **************************************************************** */
/* FIRST LOAD **************************************************************** */
/* FIRST LOAD **************************************************************** */

function firstload() {
	
	/**
	Piwik
	**/
	// Always initialize Piwik
	// pwTrack('init',5);
	
	/**
	Get URL parts 
	**/
	url = getUrl();
	
	/**
	Bind all interaction once on first load
	**/
	windowAction.onready();
	router.onready();
	popup.onready();
	
	/**
	Fire specific: firstload();
	**/
	specific_interactions.firstload();
	
	/**
	Go on and fire init
	**/
	init();
	
	/**
	Disable firstload
	**/
	isFirstLoad = 0;
	
}

/* INIT **************************************************************** */
/* INIT **************************************************************** */
/* INIT **************************************************************** */
/* INIT **************************************************************** */

function init() {

	/**
	Always start at top, or when in debug check setting
	**/
	if (
		typeof _is_development === 'undefined' || 
		(
			typeof _is_development === 'undefined' &&
			_is_development_scrolltotop === true
		) 
			
	) {
		$('html,body').scrollTop(0);
	}

	/**
	Hide loader when all loaded and ready
	**/
	specific_interactions.loader(1,0);
	
	/**
	Get URL parts 
	**/
	url = getUrl();
	
	/**
	Set load variables
	**/
	isLoading = 0;
	processForm = 0;
	
	/**
	Fire specific: init();
	**/
	specific_interactions.init();
	
	/**
	windowAction
	**/
	windowAction.onready();
	
}