/*
Version 0.102

Made by: Finally // Aleksander Schakel // www.finally.nl // aleks@finally.nl
Copyright 2017.

!Changelog

2017
	30-08-2017
	- [VAR] Changed isSmartphoneMenuOpen to isMobileMenuOpen
	- [VAR] New vars added: historyLatestPage,historyLatestPopup,historyLatestPopupPage and historyLatestPopupTitle
	29-08-2017
	- [VAR] urlParts is now url[];
	16-02-2017
	- [VARIABLES] Added: new var to fill with title; seo_title_old
	05-01-2017
	- [CHANGE] Changed: The preview URL can now be like preview-other var..like; /preview-v2 etc..
	
!Wishlist

	- Make all global variables to new var notation like variable_name
 
*/

/* SETTINGS **************************************************************** */
/* SETTINGS **************************************************************** */
/* SETTINGS **************************************************************** */
/* SETTINGS **************************************************************** */

/**
Debugging
**/
var urlAlert 				= false;

/**
Helpers and Global Variables
**/
var isFirstLoad				= 1;
var isLoading				= 1;

var isPageNow 				= 0;
var isPopupOpen 			= 0;
var isModalOpen 			= 0;
var isOverlayMenuOpen		= 0;
var isSmartphone 			= 0;
var isMobileMenuOpen		= 0;
var isOrientation			= 0;
var isScrolledNow 			= 0;
var isMenuSticky 			= 0;
var isFormProcess			= 0;
var isUploadProcess 		= 0;

var mobileMenuSetScrolled	= 0;

/**
Piwik ID
**/
var piwikID = 0;

/**
Languages
**/
var languagesCounter = 1;

/**
SEO Change title
**/
var seoTitleOld = '';

/**
Form coloring
**/
var formNormalColorBackground 	= '#ffffff';
var formNormalColorText 		= '#000000';
var formReadOnlyColorBackground = '#cccccc';
var formReadOnlyColorText 		= '#000000';
var formErrorColorBackground 	= '#ffc7c7';
var formErrorColorText 			= '#000000';

/**
Get Protocol and Host and projectUrl automatically
**/
projectProtocol = window.location.protocol;
projectHost = window.location.host;
projectUrl = window.location.pathname;
projectUrl = projectUrl.split('/');

/**
Check if host is localhost, GoServer or Preview on location or LIVE.
**/
switch(projectHost) {
	
	case 'localhost':
		subUrl = '/' + projectUrl[1]+ '/' + projectUrl[2]; 
	break;
	
	case 'preview.goserver.nl':
		subUrl = '/' + projectUrl[1]; 
	break;
	
	default:
		if (projectUrl[1].substr(0,7) == 'preview') {
			subUrl = '/preview';
		}
		else if (projectUrl[1].substr(0,11) == 'inthemaking') {
			subUrl = '/inthemaking/' + projectUrl[2]; 
		} else {
			subUrl = ''; 
		}
	break;
	
}

/**
Count URL lengts
**/		
globalUrl = projectProtocol + '//' + projectHost + subUrl;
globalUrlLength = subUrl.length;

/**
Get array of URL Parts
**/
url = getUrl();

/**
Render new SubUrl
**/
newSubUrl = renderSubUrl();

/**
Set new history url and document title
**/
historyURL = document.URL;
historyDocumentTitle = document.title;

historyLatestPageURL = '';
historyLatestPageTitle = '';
historyLatestPopupURL = '';
historyLatestPopupTitle = '';

/**
Debug: Show page
**/
if(urlAlert === true) { console.log(page); }

